import React from "react"
import styled from "@emotion/styled"

import { SmallHeroLayout } from "../components/layouts"
import Image from "../components/generic/image"
import SEO from "../components/seo"

const TeachersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1,
  h2,
  p {
    width: 100%;
    text-align: left;
    padding: 0 2rem;
  }

  & > * {
    max-width: 48rem;
  }

  @media screen and (min-width: 920px) {
    h1,
    h2,
    p {
      padding: 0;
    }
  }
`

const ImgContainer = styled.div`
  width: 100%;
  margin: 2rem 0;
  &:first-of-type {
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 920px) {
    border: solid 1px rgba(0, 0, 0, 0.25);
  }
`

const PageHeading = styled.h1`
  display: none;

  @media screen and (min-width: 920px) {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
  }
`

const TeachersPage = () => (
  <SmallHeroLayout pageTitle="Teachers">
    <SEO title="Teachers" />

    <PageHeading>Our teachers</PageHeading>

    <TeachersContainer>
      {/* DAMIEN */}
      <ImgContainer>
        <Image src="/assets/images/DamienPortrait.jpg" />
      </ImgContainer>

      <h1>Damien Hill</h1>
      <h2 className="subtitle">Six Strings Townsville</h2>

      <p>
        I love all things music and guitar. I get the most joy when I share my
        passion for music with my student. It is such a pleasure to be able to
        help someone better understand their instrument, and it is a privilege
        to help them learn and appreciate music from new and exciting angles!
      </p>
      <p>
        Through Six Strings Townsville, I want to play a part in growing the
        culture of music in our beautiful city, making Townsville known for it’s
        awesome musicians and bands! We are trying to change the world, one
        guitar lesson at a time!
      </p>

      {/* NATALIE */}
      <ImgContainer>
        <Image src="/assets/images/NataliePortrait.jpg" />
      </ImgContainer>
      <h1>Natalie Hill</h1>
      <h2 className="subtitle">Sing Smart Studios</h2>
      <p>
        One of the most important aspects of my role as a singing teacher is to
        provide my students with current, research based information on how to
        better their technique and protect vocal health. This is a passion of
        mine.
      </p>
      <p>
        I consider it a privilege to watch my students blossom as they grow in
        knowledge and understanding of their voice and achieve things they never
        thought possible – irrespective of age or singing ability.
      </p>
      <p>
        I always strive to provide a safe and encouraging environment where
        students feel comfortable experiment with their voice, allowing them to
        work towards enhancing their own unique sound, style and personal
        characteristics.
      </p>
      <p>
        At Sing Smart Studios I foster a culture where students are encouraged
        to use their musical gifts and talents to reach out and have a positive
        impact on the community in which they live.
      </p>
    </TeachersContainer>
  </SmallHeroLayout>
)

export default TeachersPage
